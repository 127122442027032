import { memo } from 'react'
import LogoSvg from '../shared/svgs/LogoSvg'
import { LISTERI_RED } from '../../lib/frontend/colors'
import { WEB_STORE_URL } from '../../lib/frontend/miscUtils'

const LogoBanner = () => {
  return (
    <a className="flex justify-center mt-20" href={WEB_STORE_URL}>
      <div
        className="border p-2 rounded-lg border-listeri-red border-opacity-80 flex flex-row items-center"
      >
        <div className="w-20 h-20">
          <LogoSvg innerFill={LISTERI_RED} outerFill="transparent" />
        </div>
        <div className="font-fancy text-listeri-red text-xl mt-2 -ml-3 tracking-wide font-semibold">
          isteri
        </div>
      </div>
    </a>

  )
}
export default memo(LogoBanner)
