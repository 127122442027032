import Head from 'next/head'
import AttributionFooter from '../components/shared/AttributionFooter'
import AddToChromeButton from '../components/index/AddToChromeButton'
import LogoBanner from '../components/index/LogoBanner'
import Background from '../components/index/Background'
import PrivacyFooter from '../components/shared/PrivacyFooter'

export default function Home () {
  return (
    <div className="">
      <Head>
        <title>Listeri - Simple Screenshot Sharing Tool</title>
      </Head>
      <Background />

      <div className="animate__animated animate__fadeIn" style={{ animationDelay: '0.25s' }}>
        <LogoBanner />

        <div className="font-fancy text-listeri-red text-3xl text-center mt-8 mb-8 tracking-wide font-semibold">
          Simple Screenshot Sharing Tool
        </div>
      </div>
      <AddToChromeButton />
      <div className="my-12">
        <AttributionFooter animationDelay="2.5s" animationType="animate__fadeIn animate__slower" />
        <div className="my-3" />
        <PrivacyFooter animationDelay="2.5s" animationType="animate__fadeIn animate__slower"/>
      </div>
    </div>
  )
}
