import { useWindowScroll } from 'react-use'

export default function Background () {
  const { y } = useWindowScroll()

  return (
    <>
      <div
        className="absolute "
        style={{
          transform: `rotate(${y / 10}deg)`,
          zIndex: -1,
          paddingLeft: '25%',
          paddingTop: '-10vh',
          width: '100%',
          height: '100vh'
        }}
       >
        <span
          className="bg-triangle block w-full h-full bg-no-repeat bg-contain"
          role="img"
          aria-label="background big triangle shape">
      </span>
      </div>
    </>
  )
}
