import { memo } from 'react'
import ChromeSvg from '../shared/svgs/ChromeSvg'
import { WEB_STORE_URL } from '../../lib/frontend/miscUtils'

const AddToChromeButton = () => {
  return (
    <a
      href={WEB_STORE_URL}
      className="flex justify-center mt-8 animate__animated animate__bounceIn"
      style={{ animationDelay: '1s' }}
    >
      <div
        className="flex justify-center border rounded-lg border-listeri-red py-2 px-4 font-fancy text-white bg-listeri-red hover:bg-red-500 active:bg-red-600"
      >
        <div className="flex self-center mr-4 text-3xl">ADD TO CHROME</div>
        <div className="w-8 h-8 flex self-center">
          <ChromeSvg />
        </div>
      </div>

    </a>
  )
}
export default memo(AddToChromeButton)
