import React from 'react'

function ChromeSvg () {
  return (
    <svg
      xmlns="http://www.w3i.org/2000/svg"
      width="100%"
      height="100%"
      x="0"
      y="0"
      enableBackground="new 0 0 226 226"
      version="1.1"
      viewBox="0 0 226 226"
      xmlSpace="preserve"
    >
      <path
        d="M65.307 83.651L44.782 22.916C63.74 8.537 87.371 0 113 0c42.059 0 78.748 22.98 98.204 57.067h-95.539A57.452 57.452 0 00113 57c-20.164 0-37.833 10.662-47.693 26.651zM169 113c0 18.881-9.354 35.566-23.669 45.71l-70.927 60.523C86.447 223.61 99.444 226 113 226c62.408 0 113-50.592 113-113 0-12.563-2.053-24.644-5.837-35.933h-64.221C164.089 86.793 169 99.321 169 113zM54.658 209.79l48.776-41.621c-22.018-3.792-39.63-20.43-44.845-41.927L28.691 37.771C10.85 57.75 0 84.106 0 113c0 41.061 21.902 77.003 54.658 96.79zM113 148c19.33 0 35-15.67 35-35s-15.67-35-35-35-35 15.67-35 35 15.67 35 35 35z"
        style={{ fill: '#f8f8ff' }}
      ></path>
    </svg>
  )
}

export default React.memo(ChromeSvg)
