import { memo } from 'react'
import Link from 'next/link'
import cn from 'classnames'

const PrivacyFooter = ({ animationDelay = '1.5s', animationType = 'animate__FadeIn' }) => (
  <Link href="/privacy">
    <a
      className={
        cn(
          'text-center block text-sm font-fancy text-gray-500 hover:text-blue-800 active:text-gray-700',
          'text-sm animate__animated',
          animationType
        )
      }
      style={{
        animationDelay: animationDelay,
        animationDuration: '0.5s'
      }}
    >
      Privacy Policy
    </a>
  </Link>

)

export default memo(PrivacyFooter)
